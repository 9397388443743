<script setup>
    import { ref, computed, onMounted, nextTick, watch } from 'vue'
    import { useRoute, useRouter } from 'vue-router';
    import StepButton from '@/components/StepButton.vue';
    import ButtonGroupLayout from '@/components/OptionLayouts/ButtonGroup.vue';
    import BigButtonGroupLayout from '@/components/OptionLayouts/BigButtonGroup.vue';
    import SwatchesLayout from '@/components/OptionLayouts/Swatches.vue';
    import BigCirclesLayout from '@/components/OptionLayouts/BigCircles.vue';
    import DepthLayout from '@/components/OptionLayouts/Depth.vue';
    import LengthLayout from '@/components/OptionLayouts/Length.vue';
    import StepHeader from '@/components/StepHeader.vue';
    import StepFooter from '@/components/StepFooter.vue';    
    import Renderer from '@/components/Renderer.vue';
    import { useData } from '@/composables/Data';
    import { useBackend } from '@/composables/Backend';
    import { useCurrentUser } from '@/composables/CurrentUser';    
    import { useDepthDependencies } from '@/composables/DepthDependencies';

    const debug_DoNotTalkToBackendAPI = false; // debugging option

    const router = useRouter();
    const route = useRoute();
    const data = useData();
    const backend = useBackend();
    const currentUser = useCurrentUser(onUserLoadSuccess);    
    useDepthDependencies(data);

    const steps = Object.entries(data.steps).map((kv) => kv[1]).filter(step => { return step.category === 'configure'});
    const depthPicShown = ref(false);
    const preNextStepShown = ref(false);
    const email = ref('');
    const skuCheckError = ref('');
    const showMoreSteps = ref(false);
    const skipMeasureStep = computed(() => {
        if (data.currentPackage && data.currentPackage.value.skipMeasurer) {
            return true;
        }   
        else {
            return false;
        }
    });
    const nextStepInProgress = ref(false);
    const nextStepButtonText = computed(() => {
        if (nextStepInProgress.value) {
            return 'Loading...';
        }
        else {
            return 'Continue →';
        }
    });
    
    // handler for if user is logged in
    function onUserLoadSuccess(userData) {
        email.value = userData.email;
    }

    // handler for when a new option is selected
    function onSelectOption(step, selected) {
        step.selected.value = selected;             
    }

    // handler for when a depth slider is changed
    function onDepthChanged(step, ruler, newDepth) {
        ruler.current.value = newDepth;
    }

    // handler for when a length slider is changed
    function onLengthChanged(step, ruler, newLength) {
        ruler.current.value = newLength;
    }    

    // handler for continue button on modal that collects window name and user email
    async function doNextStep() {
        function go() {  
            let goToRoute = '';

            if (skipMeasureStep.value) {
                goToRoute = 'confirm';
            }
            else {
                goToRoute = 'measure';
            }
            
            nextStepInProgress.value = false;
            router.push({ name: goToRoute, params: route.params, query: route.query });
        }

        if (data.windowName.value) {            
            if (!debug_DoNotTalkToBackendAPI) {
                nextStepInProgress.value = true;

                // do stock check and continue if in stock
                let skusToCheck = [];

                // collect all sku's that  need to be checked
                steps.forEach((step) => {
                    if (step.checkStock) {
                        const selected = step.selected.value;                        
                        if (selected != null && selected.id != 'none')
                            skusToCheck.push(selected.id); // the 'value' property stores the SKU
                    }
                });

                let skusInStock = [];
                if (skusToCheck.length > 0) {
                    // check each sku against the backend
                    skusInStock = await backend.checkStock(skusToCheck);
                }

                // continue if all skus in stock
                if (skusInStock.length == skusToCheck.length) {
                    if (!email.value || (email.value && await backend.saveCartEmail(email.value))) {
                        go();
                    }
                }
                else {
                    skuCheckError.value = 'Some selected skus are out of stock or there was an error communicating with the api.';
                }
            }
            else {
                go();
            }
        }
    }

    function handleKeyDown(event) {
        if (event.key === 'Enter') {
            doNextStep();
        }
    }

    watch(preNextStepShown, (newValue) => {
        if (newValue) {
            window.addEventListener('keydown', handleKeyDown);
        } else {
            window.removeEventListener('keydown', handleKeyDown);
        }
    });

    const displayedSteps = computed(() => {
        const start = showMoreSteps.value ? data.stepsPerGroup : 0;
        const end = showMoreSteps.value ? steps.length : data.stepsPerGroup;
        return steps.slice(start, end);
    });

    const hasMoreSteps = computed(() => {
        if (data.steps.package && data.steps.package.selected.value.id === 'drapery-only') {
            return false;
        }
        return steps.length > data.stepsPerGroup;
    });

    // handler for the cancel button on the modal that collects the window name and users email for cart creation
    function cancelNextStep() {
        preNextStepShown.value = false;
        skuCheckError.value = '';
    }
    const generateStepId = (step, index) => {
        return `${step.id}-${index}`;
    }
    const openIndexes = ref(new Set()); // Using a Set to track open panels

    function toggleSteps() {
        showMoreSteps.value = !showMoreSteps.value;
        openIndexes.value.clear();

        // Determine which index to open based on the current state after toggle
        if (showMoreSteps.value) {
            //const nextGroupStartIndex = data.stepsPerGroup;
            // openIndexes.value.add(nextGroupStartIndex);
            openIndexes.value.add(0);
        }
    }

    function toggle(index, disabled) {
        // Check if the step is disabled
        if (disabled && disabled.value) {
            // Prevent toggling if the step is disabled
            return;
        }

        if (openIndexes.value.has(index)) {
            openIndexes.value.delete(index);
        } else {
            openIndexes.value.clear();
            openIndexes.value.add(index);
        }
    }

    function isOpen(index) {
        return openIndexes.value.has(index);
    }

    // Open Rod Size if cafe curtain hardware ONLY
    const isHardwareOnly = computed(() => {
        return data.type === 'cafeCurtains' && data.steps.package.selected.value.id === 'hardware-only';
    });
    watch(isHardwareOnly, (newVal) => {
        if (newVal) {
            toggle(5, { value: false });
        }
    });

    // Drapery BG
    // const productTypeBG = computed(() => {
    //     return data.type === 'drapery' ? 'product-image' : 'product-image';
    // });

    const mountPostionText = computed(() => {
        if (data.steps.mountPosition != undefined ) {
            return data.steps.mountPosition.selected.value.id;
        } else {
            return ''
        }
    })

    watch(showMoreSteps, (newVal) => {
        if (newVal === true) { // Check if the new value shows the second set
            nextTick().then(setupLinks);
        } else {
            setupLinks;
        }
    });

    // @todo - fix state issue
    function setupLinks() {
        const links = document.getElementsByClassName('window-depth-pic-link');
        
        Array.from(links).forEach(link => {
            link.addEventListener('click', () => {
                depthPicShown.value = true;
            });
        });
    }    

    onMounted(() => {
        nextTick().then(setupLinks);
    });

    // onMounted(() => {
    //     const links = document.getElementsByClassName('window-depth-pic-link');
    //     for (let i = 0; i < links.length; i++) {
    //         links[i].addEventListener('click', () => {
    //             depthPicShown.value = true;
    //         });
    //     }
    // });
</script>

<template>
    <div class="container-fluid bg--product-configure">
        <!-- <div :class="['product-image product-hero__image', productTypeBG]"></div> -->
        <div class="product-config">
            <div class="config-wrapper product-hero__info-wrapper">
                <div class="row product-title product-hero__title">
                    <div class="col">
                        <h1 class="product-form__title color--white">{{ data.heading }}</h1>
                        <h2 class="pretext color--chantilly">{{ data.subheading }}</h2>
                    </div>        
                </div>
                <BCard no-body class="product-hero__form">
                    <BForm validated>                        
                        <div id="configurator" class="accordion" role="tablist">
                            <div v-for="(step, index) in displayedSteps" :key="index" class="config-item">
                                <StepButton @click="toggle(index, step.disabled)" :step="step" :index="showMoreSteps ? index + data.stepsPerGroup : index" :id="generateStepId(step, index)" :class="{'first': index === 0}"></StepButton>
                                <transition name="accordion">
                                    <div v-if="isOpen(index)" :id="generateStepId(step, index)" accordian="configurator" class="step-contents config-item__body">
                                        <div class="step-contents-inner">
                                            <StepHeader :step="step" />
                                            <ButtonGroupLayout v-if="step.layout === 'button-group'" :step="step" @select-option="onSelectOption"></ButtonGroupLayout>
                                            <BigButtonGroupLayout v-else-if="step.layout === 'big-button-group'" :step="step" @select-option="onSelectOption"></BigButtonGroupLayout>
                                            <SwatchesLayout v-else-if="step.layout === 'swatches'" :step="step" @select-option="onSelectOption"></SwatchesLayout>
                                            <BigCirclesLayout v-else-if="step.layout === 'big-circles'" :step="step" @select-option="onSelectOption"></BigCirclesLayout>
                                            <DepthLayout v-else-if="step.layout === 'depth'" :step="step" @select-option="onSelectOption" @depth-changed="onDepthChanged"></DepthLayout>                                        
                                            <LengthLayout v-else-if="step.layout === 'length'" :step="step" :shouldShowRuler="skipMeasureStep" @select-option="onSelectOption" @length-changed="onLengthChanged"></LengthLayout>
                                            <span v-else>Unknown step option layout</span>
                                            <StepFooter :step="step" :selectedMount="mountPostionText" />
                                        </div>
                                    </div>
                                </transition>
                            </div>
                            <div class="wrapper__config-btn">
                                <button v-if="hasMoreSteps" @click="toggleSteps" :class="{ 'back__config-btn': showMoreSteps, 'start__config-btn': !showMoreSteps }">
                                    {{ showMoreSteps ? '← Back' : 'Let\'s Customize  →' }}
                                </button>
                                
                                <!-- @todo fix this to be better -->
                                <div v-if="showMoreSteps == true" @click="preNextStepShown = !preNextStepShown" class="add-to-cart__config-btn">
                                    <span v-if="skipMeasureStep" class="add-cart__text">Confirm Details</span>
                                    <div v-else class="add-cart__text">
                                        <span>Measure & Price</span>
                                        <span> →</span>
                                    </div>
                                </div>
                                <div v-else-if="showMoreSteps == false && hasMoreSteps == false" @click="preNextStepShown = !preNextStepShown" class="add-to-cart__config-btn alt-config">
                                    <span v-if="skipMeasureStep" class="add-cart__text">Confirm Details</span>
                                    <div v-else class="add-cart__text">
                                        <span>Measure & Price</span>
                                        <span> →</span>
                                    </div>
                                </div>
                            </div>
                            <BModal class="modal" id="modal-pre-measure" @ok="doNextStep" @cancel="cancelNextStep" v-model="preNextStepShown" :okTitle="nextStepButtonText" cancel-title="← Cancel" hideHeaderClose hideHeader centered @hide.prevent okVariant="measurer" cancelVariant="configurator">
                                <BForm class="">
                                    <div class="modal__header">
                                        <h3>{{ data.preMeasureTitle }}</h3>
                                        <p>We'll label the packaging for you so installation is a breeze.</p>
                                    </div>
                                    <div class="modal__body">
                                        <BFormGroup class="">                                    
                                            <BFormInput class="text-input__field" id="package-label" placeholder="e.g. Primary Bedroom West" required size="lg" v-model="data.windowName.value" />
                                        </BFormGroup>
                                        <BFormGroup v-if="currentUser.isLoggedIn.value == false" description="Providing your email will ensure all your customizations are saved in case you want to check out later.">                                    
                                            <BFormInput class="text-input__field" id="email" placeholder="Email address (optional)" size="lg" v-model="email" />
                                        </BFormGroup>
                                    </div>
                                </BForm>
                            </BModal>
                        </div>
                    </BForm>
                </BCard>
            </div>
            <div class="render-wrapper">
                <Renderer></Renderer>
            </div>
        </div>
    </div>
    <BModal id="modal-depth-measure-pic" v-model="depthPicShown" hideHeader hideFooter centered>
        <img src="https://everhem.blob.core.windows.net/assets/window-depth.jpg" style="width: 100%;" />
    </BModal>
</template>
<style>

</style>