<script setup>
    import { computed } from 'vue';
    import Image from '@/components/Image.vue';

    const props = defineProps(['step']);
    const emit = defineEmits(['select-option']);

    const categories = computed(() => {
        var categories = new Set();
        for (let i = 0; i < props.step.options.length; i++) {
            categories.add(props.step.options[i].category);
        } 
        return categories;
    });

    function categoryOptions(category) {
        var matches = [];
        for (let i = 0; i < props.step.options.length; i++) {
            if (props.step.options[i].category === category) {
                matches.push(props.step.options[i]);
            }            
        } 
        return matches;
    }
</script>

<template>
    <a class="config-item__help-link" href="#">
        <Image class="step__help-link-icon icon icon--left" src="icon-swatch.svg" />
        <div class="step__help-link-text">
            Order Swatch Samples
        </div>
    </a>
    <div v-for="(category, category_index) in categories" :key="category_index">
        <div class="option-category-heading">{{ category }}</div>
        <BButton class="step-option swatch swatch-toggle" 
            v-for="(option, option_index) in categoryOptions(category)" :key="option_index"            
            :title="option.label"
            :class="{ 'active': step.selected.value.id === option.id }"
            @click="emit('select-option', step, option)"
            :disabled="option.disabled && option.disabled.value" >
                <Image :src="option.image" />
        </BButton>
    </div>
</template>